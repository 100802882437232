"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.systemsLoading = exports.systemsInfo = exports.setSelectedWaterDest = exports.setSelectedFilterData = exports.setSelectedDetails = exports.setSelectedDate = exports.setPartialSysLoading = exports.serviceLog = exports.serviceHistory = exports.selectSystems = exports.initialState = exports.imageData = exports.imageArrayList = exports.default = exports.accessorySelected = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  sysLoading: false,
  systems: [],
  selectedSystems: {},
  selectedAccessory: {},
  partialSysLoading: false,
  selectedDate: "",
  selectedWaterDest: {},
  selectedDetails: {},
  serviceLogs: {},
  serviceHistoryDetails: {},
  imageDataAdded: [],
  filterData: {},
  imageList: []
};
const systemsSlices = (0, _toolkit.createSlice)({
  name: "systems",
  initialState,
  reducers: {
    systemsLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.sysLoading = action.payload;
      } else {
        state.sysLoading = true;
      }
    },
    systemsInfo: (state, action) => {
      state.sysLoading = false;
      state.systems = action.payload;
    },
    selectSystems: (state, action) => {
      state.sysLoading = false;
      state.selectedSystems = action.payload;
    },
    accessorySelected: (state, action) => {
      state.sysLoading = false;
      state.selectedAccessory = action.payload;
    },
    setPartialSysLoading: (state, action) => {
      state.sysLoading = false;
      state.partialSysLoading = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setSelectedWaterDest: (state, action) => {
      state.selectedWaterDest = action.payload;
    },
    setSelectedDetails: (state, action) => {
      state.selectedDetails = action.payload;
    },
    setSelectedFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    serviceLog: (state, action) => {
      state.sysLoading = false;
      state.serviceLogs = action.payload;
    },
    serviceHistory: (state, action) => {
      state.sysLoading = false;
      state.serviceHistoryDetails = action.payload;
    },
    imageData: (state, action) => {
      state.sysLoading = false;
      state.imageDataAdded = action.payload;
    },
    imageArrayList: (state, action) => {
      state.sysLoading = false;
      state.imageList = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = systemsSlices;
const {
  systemsLoading,
  systemsInfo,
  selectSystems,
  accessorySelected,
  setPartialSysLoading,
  setSelectedDate,
  setSelectedDetails,
  setSelectedFilterData,
  setSelectedWaterDest,
  serviceLog,
  serviceHistory,
  imageData,
  imageArrayList
} = actions;
exports.imageArrayList = imageArrayList;
exports.imageData = imageData;
exports.serviceHistory = serviceHistory;
exports.serviceLog = serviceLog;
exports.setSelectedWaterDest = setSelectedWaterDest;
exports.setSelectedFilterData = setSelectedFilterData;
exports.setSelectedDetails = setSelectedDetails;
exports.setSelectedDate = setSelectedDate;
exports.setPartialSysLoading = setPartialSysLoading;
exports.accessorySelected = accessorySelected;
exports.selectSystems = selectSystems;
exports.systemsInfo = systemsInfo;
exports.systemsLoading = systemsLoading;
var _default = exports.default = reducer;