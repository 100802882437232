"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSideMenuState = exports.loginUserInfo = exports.loginReset = exports.loginLoading = exports.loginError = exports.initialState = exports.default = exports.clearLogin = exports.SetUserRoles = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  loading: false,
  userInfo: {
    email: '',
    password: '',
    challengeName: '',
    code: '',
    firstName: "Guest"
  },
  userRoles: {},
  currentSideMenuState: '',
  error: ''
};
const loginSlice = (0, _toolkit.createSlice)({
  name: 'login',
  initialState,
  reducers: {
    loginLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.loading = action.payload;
      } else {
        state.loading = true;
      }
    },
    loginError: (state, action) => {
      state.error = action.payload;
    },
    loginUserInfo: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    },
    loginReset: state => {
      localStorage.removeItem('app-logout');
      state.userInfo = initialState.userInfo;
      state.error = initialState.error;
    },
    SetUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    clearLogin: state => {
      localStorage.removeItem('app-logout');
    },
    setSideMenuState: (state, action) => {
      state.currentSideMenuState = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = loginSlice;
const {
  loginLoading,
  loginError,
  loginUserInfo,
  loginReset,
  SetUserRoles,
  clearLogin,
  setSideMenuState
} = actions;
exports.setSideMenuState = setSideMenuState;
exports.clearLogin = clearLogin;
exports.SetUserRoles = SetUserRoles;
exports.loginReset = loginReset;
exports.loginUserInfo = loginUserInfo;
exports.loginError = loginError;
exports.loginLoading = loginLoading;
var _default = exports.default = reducer;