"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storesLoading = exports.storesInfo = exports.selectStore = exports.initialState = exports.default = exports.currentStore = exports.clearStore = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  loading: false,
  stores: [],
  storeData: {},
  selectedStore: {}
};
const storeSlices = (0, _toolkit.createSlice)({
  name: "stores",
  initialState,
  reducers: {
    storesLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.loading = action.payload;
      } else {
        state.loading = true;
      }
    },
    currentStore: (state, action) => {
      if (action.payload !== undefined) {
        state.storeData = action.payload;
      }
    },
    storesInfo: (state, action) => {
      state.loading = false;
      state.stores = [...action.payload];
    },
    clearStore: (state, action) => {
      state.loading = true;
      state.stores = [];
    },
    selectStore: (state, action) => {
      state.loading = false;
      state.selectedStore = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = storeSlices;
const {
  storesLoading,
  storesInfo,
  currentStore,
  selectStore,
  clearStore
} = actions;
exports.clearStore = clearStore;
exports.selectStore = selectStore;
exports.currentStore = currentStore;
exports.storesInfo = storesInfo;
exports.storesLoading = storesLoading;
var _default = exports.default = reducer;