"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.default = exports.countriesListData = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  countriesDataLoading: false,
  countriesList: {}
};
const alertSlices = (0, _toolkit.createSlice)({
  name: "countries",
  initialState,
  reducers: {
    countriesListData: (state, action) => {
      state.countriesDataLoading = false;
      state.countriesList = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = alertSlices;
const {
  countriesListData
} = actions;
exports.countriesListData = countriesListData;
var _default = exports.default = reducer;