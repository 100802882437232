"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.organizationName = exports.initialState = exports.default = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  orgName: {},
  loading: false
};
const orgSlices = (0, _toolkit.createSlice)({
  name: "stores",
  initialState,
  reducers: {
    organizationName: (state, action) => {
      state.loading = false;
      state.orgName = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = orgSlices;
const {
  organizationName
} = actions;
exports.organizationName = organizationName;
var _default = exports.default = reducer;