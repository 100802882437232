"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storeStack = exports.dispatcherStack = void 0;
var _react = _interopRequireDefault(require("react"));
var _StoreMainRoutes = _interopRequireDefault(require("./StoreMainRoutes"));
var _DispatcherMainRoutes = _interopRequireDefault(require("./DispatcherMainRoutes"));
var _reducers = _interopRequireDefault(require("./common_features/redux/reducers"));
const navigationStack = {
  "store": _StoreMainRoutes.default,
  "dispatcher": _DispatcherMainRoutes.default
};
const reducerStack = {
  "store": _reducers.default,
  "dispatcher": _reducers.default
};
const storeStack = exports.storeStack = {
  navigationStack: navigationStack.store,
  reducerStack: reducerStack.store
};
const dispatcherStack = exports.dispatcherStack = {
  navigationStack: navigationStack.dispatcher,
  reducerStack: reducerStack.dispatcher
};