"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSystem = exports.updateStore = exports.updateProfile = exports.storeImageUpload = exports.storeImageDownload = exports.sendNotificationCall = exports.searchStore = exports.resendInvite = exports.reSyncVerify = exports.notificationsListState = exports.markAsReadNotifications = exports.listSystems = exports.inviteEmployee = exports.imageUpload = exports.imageGet = exports.getUsers = exports.getSystemDetails = exports.getStores = exports.getStoreSystems = exports.getStoreDetails = exports.getServiceLogOperations = exports.getRoles = exports.getLastServiceRecordByType = exports.getHistoryRecords = exports.getDeviceDetails = exports.getCountryList = exports.getAlerts = exports.fetchStoreToUsers = exports.fetchManagerUser = exports.deleteUsers = exports.deleteStores = exports.deleteAccessory = exports.createNewStore = exports.contactPentair = exports.commonUpdateUser = exports.addSystem = exports.addAccessory = exports.UpdateSignIn = exports.UnTagTechnicianToStore = exports.TagTechnicianToStore = exports.GetStore = exports.GetNotifications = exports.GetNotification = exports.GetDeviceInfo = exports.GetCityState = exports.GetAddSystemDetails = exports.AddAHub = void 0;
var _awsAmplify = require("aws-amplify");
const getCountryList = async () => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.get('GetCountry', '/common/countries', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getCountryList = getCountryList;
const getStores = async page => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        entity: "stores",
        limit: 25,
        pageNo: page
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("GetStores", "/common/search", request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getStores = getStores;
const createNewStore = async _ref => {
  let {
    name,
    phoneNo,
    address,
    imageSubPath
  } = _ref;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    if (phoneNo) {
      let plusPrefix = "+";
      phoneNo = phoneNo.charAt(0) == "+" ? phoneNo : plusPrefix.concat("", phoneNo);
    }
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: {
        name,
        phoneNo,
        address,
        imageSubPath
      }
    };
    const response = await _awsAmplify.API.post('CreateStore', '/org-owner/store', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.createNewStore = createNewStore;
const updateStore = async _ref2 => {
  let {
    storeId,
    name,
    phoneNo,
    address
  } = _ref2;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    if (phoneNo) {
      let plusPrefix = "+";
      phoneNo = phoneNo.charAt(0) == "+" ? phoneNo : plusPrefix.concat("", phoneNo);
    }
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: {
        name,
        phoneNo,
        address
      }
    };
    const response = await _awsAmplify.API.put('CreateStore', '/org-owner/store/' + storeId, request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.updateStore = updateStore;
const GetStore = async id => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.get('GetStore', '/common/store/' + id, request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.GetStore = GetStore;
const getRoles = async () => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.get('GetRoles', '/common/roles', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getRoles = getRoles;
const getUsers = async (roleId, userId) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.get('GetUsers', "/admin/users?roleId=".concat(roleId, "&userId=").concat(encodeURIComponent(userId)), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getUsers = getUsers;
const commonUpdateUser = async (userType, userId, email, firstName, lastName, primaryPhone) => {
  try {
    const reqBody = {
      userType: userType,
      id: userId,
      email: email,
      firstName: firstName,
      lastName: lastName,
      primaryPhone: primaryPhone
    };
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: reqBody
    };
    const response = await _awsAmplify.API.put('commonUpdateUser', '/common/user', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.commonUpdateUser = commonUpdateUser;
const inviteEmployee = async employeeData => {
  const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
  const request = {
    headers: {
      'x-amz-id-token': token
    },
    body: {
      "email": employeeData.email,
      "firstName": employeeData.fName,
      "lastName": employeeData.lName,
      "orgId": employeeData.orgID,
      "spId": "null",
      // "roleId": "321724df-603d-58db-9c92-b03cd9d2b96d"
      "roleId": employeeData.roleId
    }
  };
  const response = await _awsAmplify.API.post('InviteEmployee', '/common/add-user', request);
  return Promise.resolve(response);
};
exports.inviteEmployee = inviteEmployee;
const fetchManagerUser = async configData => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: {
        "userType": [configData.userType],
        "region": 'us-east-1',
        "actionType": 'list_user_all',
        "limit": configData.limit || 10,
        "pageNo": {
          [configData.userType]: configData.pageNo || 1
        }
      }
    };
    if (configData.query) {
      request.body["attribute"] = configData.attribute;
      request.body["query"] = configData.query;
    }
    const response = await _awsAmplify.API.post('InviteEmployeeList', '/common/listUsers', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.fetchManagerUser = fetchManagerUser;
const fetchStoreToUsers = async configData => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: {
        "userType": [configData.userType],
        "region": 'us-east-1',
        "actionType": 'list_store_users',
        "storeAccessType": configData.storeAccessType,
        "storeId": configData.storeId,
        "limit": configData.limit || 10,
        "pageNo": {
          [configData.userType]: configData.pageNo
        }
      }
    };
    if (configData.query) {
      request.body["attribute"] = configData.attribute;
      request.body["query"] = configData.query;
    }
    const response = await _awsAmplify.API.post('InviteEmployeeList', '/common/listUsers', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.fetchStoreToUsers = fetchStoreToUsers;
const resendInvite = async userList => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: {
        "users": userList
      }
    };
    const response = await _awsAmplify.API.post('ResendInvite', '/common/resend-invitation', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.resendInvite = resendInvite;
const GetCityState = async _ref3 => {
  let {
    zipcode,
    country
  } = _ref3;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: {
        "request": {
          "type": "geocode",
          "params": "components=postal_code:".concat(zipcode, "|country:").concat(country)
        }
      }
    };
    const response = await _awsAmplify.API.post('GetCityState', '/common/google', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.GetCityState = GetCityState;
const searchStore = async _ref4 => {
  let {
    entity,
    limit,
    pageNo,
    attribute,
    query
  } = _ref4;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    let body = {
      entity,
      limit,
      pageNo
    };
    if (attribute && query) {
      body = {
        entity,
        limit,
        pageNo,
        attribute,
        query
      };
    }
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body
    };
    const response = await _awsAmplify.API.post('searchStore', '/common/search', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.searchStore = searchStore;
const updateProfile = async (firstName, lastName, address, phone, optPayload, isDeletePhone) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const newBody = {
      firstName: firstName,
      address: address,
      lastName: lastName,
      ...optPayload,
      ...isDeletePhone
    };
    if (phone && phone.length > 1) {
      let plusPrefix = "+";
      let number = phone;
      newBody.primaryPhone = phone.charAt(0) == "+" ? phone : plusPrefix.concat("", number);
    } else if (phone && phone.length === 1) {
      newBody.primaryPhone = "";
    }
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: newBody
    };
    const response = await _awsAmplify.API.put('updateProfile', '/common/update-profile', request);
    await _awsAmplify.Auth.currentAuthenticatedUser();
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.updateProfile = updateProfile;
const GetNotification = async (email, sms, helpfulTips, phoneNumber) => {
  const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
  const newBody = {
    "langPref": "en_US",
    "pref": {
      "email": email,
      "sms": sms,
      "helpfulTips": helpfulTips
    }
  };
  if (phoneNumber) {
    let plusPrefix = "+";
    let number = phoneNumber;
    newBody.phoneNumber = phoneNumber.charAt(0) == "+" ? phoneNumber : plusPrefix.concat("", number);
  }
  const request = {
    headers: {
      'x-amz-id-token': token
    },
    body: newBody
  };
  const response = await _awsAmplify.API.post('GetNotification', '/user-notification-service/notifypref', request);
  return Promise.resolve(response);
};
exports.GetNotification = GetNotification;
const UnTagTechnicianToStore = async attr => {
  const {
    storeId,
    userIds
  } = attr;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    let body = {
      userIds
    };
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body
    };
    const response = await _awsAmplify.API.del('UnTagStores', "/common/store/".concat(storeId, "/access"), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.UnTagTechnicianToStore = UnTagTechnicianToStore;
const TagTechnicianToStore = async attr => {
  const {
    storeIds,
    userId,
    requestType
  } = attr;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    let body = {
      storeIds,
      userId,
      requestType
    };
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body
    };
    const response = await _awsAmplify.API.post('TagStores', '/common/store-tagging', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.TagTechnicianToStore = TagTechnicianToStore;
const getStoreSystems = async storeId => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.get("GetStoreSystem", "/stores/".concat(storeId, "/systems"), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getStoreSystems = getStoreSystems;
const getServiceLogOperations = async (systemId, pageNo, serviceLogsType, fromOnDate, toOnDate) => {
  try {
    const dateObj = !!fromOnDate && !!toOnDate ? {
      ...(!!fromOnDate && {
        fromOnDate: fromOnDate
      }),
      ...(!!toOnDate && {
        toOnDate: toOnDate
      })
    } : {};
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        pageNo: pageNo,
        serviceLogsType: serviceLogsType,
        ...dateObj
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    console.log("request==", request);
    const response = await _awsAmplify.API.post("GetStoreSystem", "/systems/".concat(systemId, "/services"), request);
    return Promise.resolve(response);
  } catch (error) {
    return error;
  }
};
exports.getServiceLogOperations = getServiceLogOperations;
const getHistoryRecords = async (storeId, orgId, systemId, serviceIds) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        storeId: storeId,
        orgId: orgId,
        systemId: systemId,
        serviceIds: serviceIds
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("GetSystemDetails", "/system/services", request);
    return Promise.resolve(response);
  } catch (error) {
    return error;
  }
};
exports.getHistoryRecords = getHistoryRecords;
const updateSystem = async data => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        ...data
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.put("UpdateSystem", "/user/system", request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.updateSystem = updateSystem;
const addSystem = async systemData => {
  try {
    const currentUser = await _awsAmplify.Auth.currentAuthenticatedUser();
    const currentSession = await _awsAmplify.Auth.currentSession();
    currentUser.refreshSession(currentSession.refreshToken);
    const token = currentSession.getIdToken().getJwtToken();
    const request = {
      body: {
        ...systemData
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("AddSystem", "/systems", request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.addSystem = addSystem;
const listSystems = async () => {
  try {
    const myInit = {
      headers: {
        Authorization: "None"
      }
    };
    const response = await _awsAmplify.API.get("ListSystems", "/system_ids", myInit);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.listSystems = listSystems;
const getStoreDetails = async storeId => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.get("GetStoreDetails", "/common/store/".concat(storeId), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getStoreDetails = getStoreDetails;
const GetDeviceInfo = async qrData => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        ...qrData
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("ProductFound", "/authenticateDevice", request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.GetDeviceInfo = GetDeviceInfo;
const AddAHub = async hubData => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        ...hubData
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("AddHub", "/serviceprovider/store-device-map", request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.AddAHub = AddAHub;
const deleteAccessory = async (systemId, accessoryId) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.del("DeleteAccessory", "/system/".concat(systemId, "/accessory/").concat(accessoryId), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.deleteAccessory = deleteAccessory;
const getAlerts = async storeId => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.get("GetAlerts", "/stores/".concat(storeId, "/system/alerts"), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getAlerts = getAlerts;
const getSystemDetails = async systemId => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.get("GetSystemDetails", "/systems/".concat(systemId), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getSystemDetails = getSystemDetails;
const getLastServiceRecordByType = async (systemId, serviceLogType, refAppId) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        refAppId
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("GetSystemDetails", "/systems/".concat(systemId, "/services/serviceLogType/").concat(serviceLogType), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getLastServiceRecordByType = getLastServiceRecordByType;
const deleteStores = async storeIds => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        storeIds
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("DeleteStores", '/common/delete-stores', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.deleteStores = deleteStores;
const GetNotifications = async function () {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      },
      body: params
    };
    const response = await _awsAmplify.API.post("NotificationAlert", '/user-notification-service/notifications', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.GetNotifications = GetNotifications;
const deleteUsers = async attributes => {
  const {
    userIds,
    actionType,
    userType
  } = attributes;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    let body = {
      userIds,
      actionType,
      userType
    };
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body
    };
    const response = await _awsAmplify.API.del('DeleteUsers', '/common/delete-user', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.deleteUsers = deleteUsers;
const UpdateSignIn = async () => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.post('UpdateSignIn', '/common/update-signin-status', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.UpdateSignIn = UpdateSignIn;
const getDeviceDetails = async deviceId => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.get("GetDevice", "/".concat(deviceId), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.getDeviceDetails = getDeviceDetails;
const notificationsListState = async _ref5 => {
  let {
    email,
    sms,
    helpfulTips,
    isNotifyIconClicked,
    phoneNumber
  } = _ref5;
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const newBody = {
      "langPref": "en_US",
      "pref": {
        "email": email,
        "sms": sms,
        "helpfulTips": helpfulTips
      },
      "isNotifyIconClicked": isNotifyIconClicked
    };
    if (phoneNumber.length > 9) {
      let number = phoneNumber;
      newBody.phoneNumber = number;
    }
    const request = {
      headers: {
        "x-amz-id-token": token
      },
      body: newBody
    };
    const response = await _awsAmplify.API.post("NotificationAlert", '/user-notification-service/notifypref', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.notificationsListState = notificationsListState;
const contactPentair = async (category, message) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        category,
        message
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("ContactPentair", '/common/contact-pentair', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.contactPentair = contactPentair;
const GetAddSystemDetails = async systemId => {
  try {
    const request = {
      headers: {
        Authorization: "None"
      }
    };
    const response = await _awsAmplify.API.get("GetAddSystemDetails", "/systems/".concat(systemId), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.GetAddSystemDetails = GetAddSystemDetails;
const markAsReadNotifications = async params => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        "x-amz-id-token": token
      },
      body: {
        "notificationIds": params
      }
    };
    const response = await _awsAmplify.API.put("NotificationAlert", '/user-notification-service/marknotification', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.markAsReadNotifications = markAsReadNotifications;
const addAccessory = async (systemData, systemId) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      body: {
        ...systemData
      },
      headers: {
        "x-amz-id-token": token
      }
    };
    const response = await _awsAmplify.API.post("AddAccessory", "".concat(systemId, "/references"), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.addAccessory = addAccessory;
const sendNotificationCall = async () => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.post('SendPasswordChangeNotification', '/trigger/notification', request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.sendNotificationCall = sendNotificationCall;
const reSyncVerify = async id => {
  try {
    const reqBody = {
      "attributes": ["email"]
    };
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const request = {
      headers: {
        'x-amz-id-token': token
      },
      body: reqBody
    };
    const response = await _awsAmplify.API.patch('syncverification', "/common/user/".concat(encodeURIComponent(id), "/sync-verification"), request);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.reSyncVerify = reSyncVerify;
const imageUpload = async (data, path) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const reqBody = {
      body: data,
      headers: {
        'x-amz-id-token': token,
        'Content-Type': 'image/png'
      }
    };
    const response = await _awsAmplify.API.put('s3Image', path, reqBody);
    return Promise.resolve(response);
    // document.getElementById("imageId").src = `data:image/jpeg;base64,${response}`
  } catch (e) {
    console.log(e);
  }
};
exports.imageUpload = imageUpload;
const imageGet = async path => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const reqBody = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.get('s3Image', path, reqBody);
    return Promise.resolve(response);
  } catch (e) {
    console.log(e);
  }
};
exports.imageGet = imageGet;
const storeImageUpload = async (data, path) => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const reqBody = {
      body: data,
      headers: {
        'x-amz-id-token': token,
        'Content-Type': 'image/png'
      }
    };
    const response = await _awsAmplify.API.put('storeImagesAPI', path, reqBody);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.storeImageUpload = storeImageUpload;
const storeImageDownload = async path => {
  try {
    const token = (await _awsAmplify.Auth.currentSession()).getIdToken().getJwtToken();
    const reqBody = {
      headers: {
        'x-amz-id-token': token
      }
    };
    const response = await _awsAmplify.API.get('storeImagesAPI', path, reqBody);
    return Promise.resolve(response);
  } catch (e) {
    return e;
  }
};
exports.storeImageDownload = storeImageDownload;