"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _loginReducer = _interopRequireWildcard(require("./loginReducer"));
var _storesReducer = _interopRequireWildcard(require("./storesReducer"));
var _manageUserReducer = _interopRequireWildcard(require("./manageUserReducer"));
var _userDetailsReducer = _interopRequireWildcard(require("./userDetailsReducer"));
var _systemsReducer = _interopRequireWildcard(require("./systemsReducer"));
var _gatewayReducer = _interopRequireWildcard(require("./gatewayReducer"));
var _alertReducer = _interopRequireWildcard(require("./alertReducer"));
var _orgNameReducer = _interopRequireWildcard(require("./orgNameReducer"));
var _countriesReducer = _interopRequireWildcard(require("./countriesReducer"));
var _roleReducer = _interopRequireWildcard(require("./roleReducer"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const combinedInitial = {
  login: _loginReducer.initialState,
  stores: _storesReducer.initialState,
  users: _manageUserReducer.initialState,
  userDetails: _userDetailsReducer.initialState,
  systems: _systemsReducer.initialState,
  gateway: _gatewayReducer.initialState,
  alert: _alertReducer.initialState,
  orgName: _orgNameReducer.initialState,
  countries: _countriesReducer.initialState,
  role: _roleReducer.initialState
};
const allReducers = {
  login: _loginReducer.default,
  stores: _storesReducer.default,
  users: _manageUserReducer.default,
  userDetails: _userDetailsReducer.default,
  systems: _systemsReducer.default,
  gateway: _gatewayReducer.default,
  alert: _alertReducer.default,
  orgName: _orgNameReducer.default,
  countries: _countriesReducer.default,
  role: _roleReducer.default
};
const combinedReducer = (0, _redux.combineReducers)(allReducers);
const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = {
      ...state,
      ...combinedInitial
    };
  }
  return combinedReducer(state, action);
};
var _default = exports.default = rootReducer;