"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _configLocal = require("../config/config-local");
var _awsAmplify = require("aws-amplify");
var _event = require("./event");
/**
 * Manager class to manage WebSocket that need to be created inside application.
 */

const base_url = _configLocal.webSocketURL;
let userToken = null;
let websocketManager = null;
let socketObj = null;
let isConnected = false;
let currentEvent = null;
let isForceClose = false;
class DeviceWebSocketManager {
  constructor() {
    (0, _defineProperty2.default)(this, "_handleAppStateChange", e => {
      console.log(isConnected, websocketManager);
      if (websocketManager && !isConnected) {
        var _websocketManager;
        (_websocketManager = websocketManager) === null || _websocketManager === void 0 ? void 0 : _websocketManager.connect().sendEvent(currentEvent);
      } else {
        websocketManager = new DeviceWebSocketManager();
        websocketManager.connect().sendEvent(currentEvent);
        _event.eventEmitter.dispatch("socketOnError", {
          code: 1001
        });
      }
    });
  }
  static getInstance() {
    if (!websocketManager) {
      websocketManager = new DeviceWebSocketManager();
    }
    return websocketManager;
  }
  connect() {
    if (websocketManager && (!socketObj || !isConnected)) {
      websocketManager.createConnection();
      if (!window.onfocus) window.onfocus = websocketManager._handleAppStateChange;
    }
    return websocketManager;
  }
  /**
   * do not call this method from outside
   */
  async createConnection() {
    if (!socketObj || !isConnected) {
      _awsAmplify.Auth.currentAuthenticatedUser().then(async user => {
        const token = (await _awsAmplify.Auth.currentSession()).getAccessToken().getJwtToken();
        const params = {
          token: token
        };
        userToken = token;
        const queryString = Object.keys(params).map(key => "".concat(key, "=").concat(params[key])).join("&");
        socketObj = new WebSocket("".concat(base_url, "?").concat(queryString));
        websocketManager.registerListener(socketObj);
        return socketObj;
      }).catch(error => null);
    }
    return socketObj;
  }
  /**
   * do not call this method from outside
   */
  registerListener(socketObj) {
    socketObj.onopen = event => {
      isConnected = true;
      isForceClose = false;
      console.log("Web Socket Connection Established", event);
      console.log("WS Connection Date Time", new Date());
    };
    socketObj.onmessage = event => {
      let data = JSON.parse(event.data);
      console.log(event.data, "event.data");
      _event.eventEmitter.dispatch("socketOnMessage", data);
    };
    socketObj.onclose = event => {
      isConnected = false;
      socketObj = null;
      userToken = null;
      websocketManager = null;
      console.log(event, "onClose");
      if ((event === null || event === void 0 ? void 0 : event.reason) === "Stream end encountered") {
        _event.eventEmitter.dispatch("socketOnError", event);
      }
    };
    socketObj.onerror = event => {
      isConnected = false;
      console.log(event, "onError");
      try {
        console.log("WS Connection Error Occur", event);
        if (!isForceClose) {
          _event.eventEmitter.dispatch("socketOnError", event);
        }
      } catch (error) {
        if (!isForceClose) {
          _event.eventEmitter.dispatch("socketOnError", error);
        }
      }
    };
  }
  /**
   * call this to send event and receive response for perticular event
   * @param {*} request
   * @param {*} routeName
   * @param {*} callbackMessage
   */
  sendEvent(request) {
    let timeInterval = setInterval(() => {
      if (isConnected) {
        try {
          currentEvent = request;
          socketObj.send(JSON.stringify(request));
          clearInterval(timeInterval);
        } catch (error) {
          console.log("WS Send Event Error :==> ", error);
        }
      }
    }, 500);
  }

  /**
   * Call this method to unsbscribe perticular action
   * @param {*} request
   */
  disconnectEvent(request) {
    if (isConnected) socketObj.send(request);
    userToken = null;
  }
  closeSocket() {
    if (socketObj) {
      isConnected = false;
      socketObj.close();
    }
  }
  setIsConnect(value) {
    isConnected = value;
  }
  static getIsConnect() {
    return isConnected;
  }
  setSocketObject(object) {
    socketObj = object;
  }
}
exports.default = DeviceWebSocketManager;