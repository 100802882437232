// import { awsConfig } from "./config-local";
import { awsConfig_us_east_1 } from './config-us-east-1'

export const US_REGION = 'us-east-1';

export const DEFAULT_REGION = US_REGION;

const RegionAWSDynamicConfigMap = {
  [US_REGION]: awsConfig_us_east_1,
};

export const getAWSDynamicConfigByRegion = (region = DEFAULT_REGION) => (
  RegionAWSDynamicConfigMap[region]
  || RegionAWSDynamicConfigMap[DEFAULT_REGION]
);