"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parentRoutPath = exports.onboardRoutPath = exports.default = exports.baseRoutes = exports.basePath = void 0;
const basePath = exports.basePath = "";
const parentRoutPath = exports.parentRoutPath = "/Admin";
const onboardRoutPath = exports.onboardRoutPath = "/Onboard";
const baseRoutes = exports.baseRoutes = {
  basePath: {
    path: "/",
    pathName: "Admin",
    useLink: "/"
  },
  admin: {
    path: "".concat(basePath).concat(parentRoutPath),
    pathName: "Admin",
    useLink: "".concat(parentRoutPath)
  },
  onboard: {
    path: "".concat(basePath).concat(onboardRoutPath),
    pathName: "Admin",
    useLink: "".concat(onboardRoutPath)
  },
  login: {
    path: "".concat(basePath, "/Login"),
    pathName: "Login",
    useLink: "/Login"
  },
  forgotPasswordOtp: {
    path: "".concat(basePath, "/ForgotPasswordOtp"),
    pathName: "Forgot Password Otp",
    useLink: "/ForgotPasswordOtp"
  },
  forgotPasswordReset: {
    path: "".concat(basePath, "/ForgotPasswordReset"),
    pathName: "Forgot Password Reset",
    useLink: "".concat(onboardRoutPath, "/ForgotPasswordReset")
  },
  forgotPassword: {
    path: "".concat(basePath, "/ForgotPassword"),
    pathName: "Forgot Password",
    useLink: "/ForgotPassword"
  },
  forceChangePassword: {
    path: "".concat(basePath, "/ForceChangePassword"),
    pathName: "Force Change Password",
    useLink: "".concat(onboardRoutPath, "/ForceChangePassword")
  },
  // welcomeScreen: {
  //     path: `${basePath}/WelcomeScreen`,
  //     pathName: "Welcome Screen",
  //     useLink: `/WelcomeScreen`
  // },
  createProfile: {
    path: "".concat(basePath, "/CreateProfile"),
    pathName: "Create Profile",
    useLink: "".concat(onboardRoutPath, "/CreateProfile")
  },
  editProfile: {
    path: "".concat(basePath, "/EditProfile"),
    pathName: "Edit Profile",
    useLink: "".concat(parentRoutPath, "/EditProfile")
  },
  userManage: {
    path: "".concat(basePath, "/UserManage"),
    pathName: "User Manage",
    useLink: "".concat(parentRoutPath, "/UserManage")
  },
  addUserInvite: {
    path: "".concat(basePath, "/AddUserInvite"),
    pathName: "Addc Userc Invite",
    useLink: "".concat(parentRoutPath, "/AddUserInvite")
  },
  dispatcherStoreDashboard: {
    path: "".concat(basePath, "/dispatcherStoreDashboard"),
    pathName: "Dispatcher Store",
    useLink: "".concat(parentRoutPath, "/dispatcherStoreDashboard")
  },
  techDispatcher: {
    path: "".concat(basePath, "/techDispatcher"),
    pathName: "Tech Dispatcher",
    useLink: "".concat(parentRoutPath, "/techDispatcher")
  },
  storeDashboard: {
    path: "".concat(basePath, "/storeDashboard"),
    pathName: "Store Dashboard",
    useLink: "".concat(parentRoutPath, "/storeDashboard")
  },
  ownerDashboard: {
    path: "".concat(basePath, "/ownerDashboard"),
    pathName: "Owner Dashboard",
    useLink: "".concat(parentRoutPath, "/ownerDashboard")
  },
  manageStore: {
    path: "".concat(basePath, "/manageStore"),
    pathName: "Store Management",
    useLink: "".concat(parentRoutPath, "/manageStore")
  },
  storeDetails: {
    path: "".concat(basePath, "/storeDetails"),
    pathName: "Store Details",
    useLink: "".concat(parentRoutPath, "/storeDetails")
  },
  serviceLogs: {
    path: "".concat(basePath, "/ServiceLogs"),
    pathName: "Service Logs",
    useLink: "".concat(parentRoutPath, "/ServiceLogs")
  },
  serviceLogOperations: {
    path: "".concat(basePath, "/ServiceLogOperations"),
    pathName: "ServiceLogOperations",
    useLink: "".concat(parentRoutPath, "/ServiceLogOperations")
  },
  imageUpload: {
    path: "".concat(basePath, "/ImageUploadContainer"),
    pathName: "ImageUploadContainer",
    useLink: "".concat(parentRoutPath, "/ImageUploadContainer")
  },
  createStore: {
    path: "".concat(basePath, "/createStore"),
    pathName: "Create New Store",
    useLink: "".concat(parentRoutPath, "/createStore")
  },
  NotificationPreferences: {
    path: "".concat(basePath, "/NotificationPreferences"),
    pathName: "Notification Preferences",
    useLink: "".concat(parentRoutPath, "/NotificationPreferences")
  },
  NotificationPreferencesOnboard: {
    path: "".concat(basePath, "/NotificationPreferencesOnboard"),
    pathName: "Notification Preferences",
    useLink: "".concat(onboardRoutPath, "/NotificationPreferencesOnboard")
  },
  ChangeCurrentPassword: {
    path: "".concat(basePath, "/ChangeCurrentPassword"),
    pathName: "ChangeCurrentPassword",
    useLink: "".concat(parentRoutPath, "/ChangeCurrentPassword")
  },
  addSystem: {
    path: "".concat(basePath, "/addSystem"),
    pathName: "addSystem",
    useLink: "".concat(parentRoutPath, "/addSystem")
  },
  // addHub: {
  //     path: `${basePath}/addHub`,
  //     pathName: "addHub",
  //     useLink: `${parentRoutPath}/addHub`
  // },
  editWaterDestination: {
    path: "".concat(basePath, "/editWaterDestination"),
    pathName: "editWaterDestination",
    useLink: "".concat(parentRoutPath, "/editWaterDestination")
  },
  systemDetail: {
    path: "".concat(basePath, "/systemDetail"),
    pathName: "System Detail",
    useLink: "".concat(parentRoutPath, "/systemDetail")
  },
  WaterInfo: {
    path: "".concat(basePath, "/waterInfo"),
    pathName: "Water Info",
    useLink: "".concat(parentRoutPath, "/waterInfo")
  },
  EditSensor: {
    path: "".concat(basePath, "/EditSensor"),
    pathName: "Edit Sensor",
    useLink: "".concat(parentRoutPath, "/EditSensor")
  },
  gatewayDetail: {
    path: "".concat(basePath, "/gatewayDetail"),
    pathName: "Gateway Detail",
    useLink: "".concat(parentRoutPath, "/gatewayDetail")
  },
  // addSensor: {
  //     path: `${basePath}/addSensor`,
  //     pathName: "addSensor",
  //     useLink: `${parentRoutPath}/addSensor`
  // },
  addAccessory: {
    path: "".concat(basePath, "/addAccessory"),
    pathName: "addAccessory",
    useLink: "".concat(parentRoutPath, "/addAccessory")
  },
  editAccessory: {
    path: "".concat(basePath, "/editAccessory"),
    pathName: "Edit Accessory",
    useLink: "".concat(parentRoutPath, "/editAccessory")
  },
  editFilter: {
    path: "".concat(basePath, "/editFilter"),
    pathName: "Edit Filter",
    useLink: "".concat(parentRoutPath, "/editFilter")
  },
  editSystem: {
    path: "".concat(basePath, "/editSystem"),
    pathName: "Edit System",
    useLink: "".concat(parentRoutPath, "/editSystem")
  },
  addNotes: {
    path: "".concat(basePath, "/addNotes"),
    pathName: "Add Notes",
    useLink: "".concat(parentRoutPath, "/addNotes")
  },
  StoreAlertsDetails: {
    path: "".concat(basePath, "/StoreAlertsDetails"),
    pathName: "Store Alerts",
    useLink: "".concat(parentRoutPath, "/StoreAlertsDetails")
  },
  StoreAlertList: {
    path: "".concat(basePath, "/StoreAlertList"),
    pathName: "Store Alerts",
    useLink: "".concat(parentRoutPath, "/StoreAlertList")
  },
  StoreAlerts: {
    path: "".concat(basePath, "/StoreAlerts"),
    pathName: "Store Alerts",
    useLink: "".concat(parentRoutPath, "/StoreAlerts")
  },
  editSensorPlacement: {
    path: "".concat(basePath, "/editSensorPlacement"),
    pathName: "Store Alerts",
    useLink: "".concat(parentRoutPath, "/editSensorPlacement")
  },
  NotificationsList: {
    path: "".concat(basePath, "/NotificationsList"),
    pathName: "Notifications List",
    useLink: "".concat(parentRoutPath, "/NotificationsList")
  },
  contactPentair: {
    path: "".concat(basePath, "/contactPentair"),
    pathName: "Contact Pentair",
    useLink: "".concat(parentRoutPath, "/contactPentair")
  },
  legal: {
    path: "".concat(basePath, "/Legal"),
    pathName: "Legal",
    useLink: "".concat(parentRoutPath, "/Legal")
  },
  emailUpdate: {
    path: "".concat(basePath, "/EmailUpdate"),
    pathName: 'EmailUpdate',
    useLink: "".concat(parentRoutPath, "/EmailUpdate")
  }
};
var _default = exports.default = baseRoutes;