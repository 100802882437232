"use strict";

module.exports = {
  Pentair_Logo_white: require('./Pentair_Logo_ccw.svg'),
  Pentair_Logo: require('./Pentair_Logo.svg'),
  Alert_Icon: require('./alerts.svg'),
  Alert_Icon_Red: require("./alerts_red.svg"),
  hem_burger_icon: require('./hemburgerIcon.svg'),
  CloseIcon: require("./closeX.svg"),
  imageUpload: require("./imageUpload.svg"),
  ArrowRight: require('./arrow_right.svg'),
  EditPencil: require('./edit_pencil.svg'),
  Plus: require('./plus.svg'),
  pentairLogo: require("./pentair-logo.png"),
  menuIcon: require("./menuicon.png"),
  alert: require("./alerts.png"),
  ownerFirstVisit: require("./firstVisitOwnerStore.png"),
  errorIcon: require("./errorIcon.svg"),
  leftImgArrow: require("./leftArrow.svg"),
  rightArrow: require("./arrowRight.svg"),
  infoIcon: require("./infoIcon.png"),
  optmizeIcon: require("./optmizeIcon.png"),
  everythingOkIcon: require("./everythingOkIcon.png"),
  filterIcon: require("./filterIcon.png"),
  mapViewIcon: require("./mapViewListIcon.png"),
  plusStoreIcon: require("./plusStoreIcon.png"),
  editWaterIcon: require("./editWaterInfo.svg"),
  addImageIcon: require("./addImage.svg"),
  listViewIcon: require("./listViewIcon.png"),
  questionIcon: require("./questionIcon.png"),
  dispatcherFirstVisit: require("./firstVisitDispatcherVisit.png"),
  leftArrow: require("./leftArrow.png"),
  smallArrow: require("./smallArrow.png"),
  notification: require("./reviewAlerts.png"),
  coldIcon: require("./coldIcon.png"),
  mrsDevice: require("./device-EV932873.png"),
  hotIcon: require("./hotIcon.png"),
  arrowUp: require("./arrow-up.svg"),
  arrowDown: require("./arrow-down.svg"),
  arrowRightColor: require("./arrow_right_color.png"),
  streamSystem: require("./steam.svg"),
  iceSystem: require("./ice.svg"),
  fountainSystem: require("./fountain.svg"),
  hotSystem: require("./hot.svg"),
  steamIcon: require("./steamIcon.png"),
  fountaineIcon: require("./fountainIcon.png"),
  iceIcon: require("./iceIcon.png"),
  greenDot: require("./greenDot.png"),
  successGreenTick: require("./successGreenTick.png"),
  rightTick: require("./rightTick.png"),
  FPOHub2: require("./FPOHub2.svg"),
  FPOHub3: require("./FPOHub3.svg"),
  Frame: require("./Frame.svg"),
  checkmark: require("./checkmark.svg"),
  trash: require("./trash.svg"),
  delete: require("./delete.svg"),
  success_check_mark: require("./success_check_mark.svg"),
  goodLevel: require("./goodLevel.png"),
  mediumLevel: require("./mediumLevel.png"),
  lowLevel: require("./lowLevel.png"),
  High: require("./high.svg"),
  Medium: require("./medium.svg"),
  Low: require("./low.svg"),
  Planned: require("./planned.svg"),
  Info: require("./info.svg"),
  InfoHolo: require("./info-holo.svg"),
  warning: require("./warning.png"),
  subscriptionExpireIcon: require("./storeSubscriptionExpireIcon.png"),
  onlineHub: require('./onlineHUB.svg'),
  offlineHub: require('./offlineHUB.svg'),
  downwardArrow: require("./Vector.png"),
  galTank: require("./galTank.png"),
  roundNegativeBtn: require("./roundNegativeBtn.png"),
  roundPositiveBtn: require("./RoundPositiveBtn.png"),
  warningSvg: require("./warning.svg"),
  Union: require("./Union.png"),
  noImg: require("./noImage.svg"),
  smallPentairLogo: require("./smallPentairLogo.svg"),
  timerLogo: require("./timeLogo.png"),
  deleteLogo: require("./deleteIcon.png"),
  lowFilter: require("./lowFilter.svg"),
  lowIcon: require("./lowIcon.svg"),
  session: require("./session.svg"),
  whitePencil: require("./whitePencil.svg"),
  cancelImg: require("./ic_cancel.svg"),
  calendar: require("./Calendar.svg")
};