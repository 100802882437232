"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectGateway = exports.initialState = exports.gatewayLoading = exports.gatewayInfo = exports.default = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  gateLoading: false,
  gateways: [],
  selectedGateway: {}
};
const gatewaySlices = (0, _toolkit.createSlice)({
  name: "gateway",
  initialState,
  reducers: {
    gatewayLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.gateLoading = action.payload;
      } else {
        state.gateLoading = true;
      }
    },
    gatewayInfo: (state, action) => {
      state.gateLoading = false;
      state.gateways = action.payload;
    },
    selectGateway: (state, action) => {
      state.gateLoading = false;
      state.selectedGateway = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = gatewaySlices;
const {
  gatewayLoading,
  gatewayInfo,
  selectGateway
} = actions;
exports.selectGateway = selectGateway;
exports.gatewayInfo = gatewayInfo;
exports.gatewayLoading = gatewayLoading;
var _default = exports.default = reducer;