"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userDetailsLoading = exports.userDetailsInfo = exports.userDetailsError = exports.initialState = exports.default = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  loading: false,
  data: {},
  error: ''
};
const userDetailsSlice = (0, _toolkit.createSlice)({
  name: 'userDetails',
  initialState,
  reducers: {
    userDetailsLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.loading = action.payload;
      } else {
        state.loading = true;
      }
    },
    userDetailsError: (state, action) => {
      state.error = action.payload;
    },
    userDetailsInfo: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = userDetailsSlice;
const {
  userDetailsLoading,
  userDetailsError,
  userDetailsInfo
} = actions;
exports.userDetailsInfo = userDetailsInfo;
exports.userDetailsError = userDetailsError;
exports.userDetailsLoading = userDetailsLoading;
var _default = exports.default = reducer;