"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectedAlertData = exports.initialState = exports.generalAlertData = exports.default = exports.alertsLoading = exports.alertData = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  alertsLoading: false,
  storeAlerts: {
    storeId: {
      systems: []
    }
  },
  generalAlerts: [],
  selectedAlert: null
};
const alertSlices = (0, _toolkit.createSlice)({
  name: "alert",
  initialState,
  reducers: {
    alertsLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.alertsLoading = action.payload;
      } else {
        state.alertsLoading = true;
      }
    },
    alertData: (state, action) => {
      state.alertsLoading = false;
      state.storeAlerts[action.payload.storeId] = action.payload.data;
    },
    generalAlertData: (state, action) => {
      state.generalAlerts = action.payload;
    },
    selectedAlertData: (state, action) => {
      state.selectedAlert = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = alertSlices;
const {
  alertsLoading,
  alertData,
  selectedAlertData,
  generalAlertData
} = actions;
exports.generalAlertData = generalAlertData;
exports.selectedAlertData = selectedAlertData;
exports.alertData = alertData;
exports.alertsLoading = alertsLoading;
var _default = exports.default = reducer;