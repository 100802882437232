"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUserScreenPref = exports.roleLoading = exports.roleInfo = exports.roleError = exports.initialState = exports.default = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  loading: false,
  data: {},
  error: '',
  userScreenPref: ""
};
const roleSlice = (0, _toolkit.createSlice)({
  name: 'role',
  initialState,
  reducers: {
    roleLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.loading = action.payload;
      } else {
        state.loading = true;
      }
    },
    roleError: (state, action) => {
      state.error = action.payload;
    },
    roleInfo: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    setUserScreenPref: (state, action) => {
      state.userScreenPref = action.payload;
    }
  }
});
const {
  actions,
  reducer
} = roleSlice;
const {
  roleLoading,
  roleError,
  roleInfo,
  setUserScreenPref
} = actions;
exports.setUserScreenPref = setUserScreenPref;
exports.roleInfo = roleInfo;
exports.roleError = roleError;
exports.roleLoading = roleLoading;
var _default = exports.default = reducer;