"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _awsAmplify = require("aws-amplify");
var _react = _interopRequireWildcard(require("react"));
var _reactRedux = require("react-redux");
var _reactRouterDom = require("react-router-dom");
var _loginReducer = require("../common_features/redux/reducers/loginReducer");
var _orgNameReducer = require("../common_features/redux/reducers/orgNameReducer");
var _userDetailsReducer = require("../common_features/redux/reducers/userDetailsReducer");
var _helper = require("../common_features/utility/helper");
var _Services = require("../common_features/utility/Services");
var _RoutePath = _interopRequireDefault(require("../RoutePath"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const AuthRoutes = props => {
  const navigate = (0, _reactRouterDom.useNavigate)();
  const dispatch = (0, _reactRedux.useDispatch)();
  const {
    userInfo
  } = (0, _reactRedux.useSelector)(state => state.login);
  const getRolesData = async () => {
    var _roles$data;
    const roles = await (0, _Services.getRoles)();
    dispatch((0, _loginReducer.SetUserRoles)(roles === null || roles === void 0 ? void 0 : roles.data));
    let roleId = roles === null || roles === void 0 ? void 0 : (_roles$data = roles.data) === null || _roles$data === void 0 ? void 0 : _roles$data[0].roleId;
    return roleId;
  };
  const getUsersData = async () => {
    var _userDetailsData$data, _userDetailsData$data2, _userDetailsData$data3, _userDetailsData$data4, _userDetailsData$data5;
    let roleID = await getRolesData();
    let user = await _awsAmplify.Auth.currentAuthenticatedUser();
    let userPoolId = user.pool.userPoolId;
    let authUsername = user.username;
    let USERID = "".concat(userPoolId, "#").concat(authUsername);
    let newUserId = USERID;
    const userDetailsData = await (0, _Services.getUsers)(roleID, newUserId);
    dispatch((0, _userDetailsReducer.userDetailsInfo)(userDetailsData.data));
    if (userDetailsData !== null && userDetailsData !== void 0 && (_userDetailsData$data = userDetailsData.data) !== null && _userDetailsData$data !== void 0 && _userDetailsData$data.isDeleted) {
      _awsAmplify.Auth.signOut().then(data => {
        navigate(_RoutePath.default.login.useLink);
      }).catch(error => {});
      return;
    }
    let organization = (userDetailsData === null || userDetailsData === void 0 ? void 0 : (_userDetailsData$data2 = userDetailsData.data) === null || _userDetailsData$data2 === void 0 ? void 0 : (_userDetailsData$data3 = _userDetailsData$data2.Organization) === null || _userDetailsData$data3 === void 0 ? void 0 : _userDetailsData$data3.name) || (userDetailsData === null || userDetailsData === void 0 ? void 0 : (_userDetailsData$data4 = userDetailsData.data) === null || _userDetailsData$data4 === void 0 ? void 0 : (_userDetailsData$data5 = _userDetailsData$data4.ServiceProvider) === null || _userDetailsData$data5 === void 0 ? void 0 : _userDetailsData$data5.name);
    dispatch((0, _orgNameReducer.organizationName)(organization));
  };
  const getCurrentRole = user => {
    let userType = user.signInUserSession.accessToken.payload["cognito:groups"][0];
    let roleId = (0, _helper.getUserTypeFromGroup)(userType);
    (0, _helper.setUserDetails)(_loginReducer.loginUserInfo, dispatch, roleId, user);
  };
  (0, _react.useEffect)(() => {
    _awsAmplify.Auth.currentUserInfo().then(() => {}).catch(e => {
      navigate(_RoutePath.default.login.useLink);
      localStorage.clear();
    });
    if (!(userInfo !== null && userInfo !== void 0 && userInfo.userType)) {
      _awsAmplify.Auth.currentAuthenticatedUser().then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          navigate(_RoutePath.default.forceChangePassword.useLink);
        } else {
          getCurrentRole(user);
          getUsersData();
        }
      }).catch(error => {
        console.log('isCurrentAuthenticatedUser- ', error);
        navigate(_RoutePath.default.login.useLink);
      });
    } else {
      getUsersData();
    }
    return null;
  }, []);
  return userInfo !== null && userInfo !== void 0 && userInfo.userType ? props.children : null;
};
var _default = exports.default = AuthRoutes;