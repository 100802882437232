"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.store = exports.persistor = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _reduxLogger = _interopRequireDefault(require("redux-logger"));
var _helper = require("../utility/helper");
var _reducers = _interopRequireDefault(require("./reducers"));
var _reduxPersist = require("redux-persist");
var _storage = _interopRequireDefault(require("redux-persist/lib/storage"));
var _reduxPersistTransformEncrypt = require("redux-persist-transform-encrypt");
const persistConfig = {
  key: 'root',
  storage: _storage.default,
  blacklist: ['login', "userDetails"],
  transforms: [(0, _reduxPersistTransformEncrypt.encryptTransform)({
    secretKey: 'my-super-secret-key',
    onError: function (error) {}
  })]
};
const persistedReducer = (0, _reduxPersist.persistReducer)(persistConfig, _reducers.default);
let store = exports.store = (0, _toolkit.configureStore)({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => {
    const env = (0, _helper.getReactEnv)();
    console.log(env, "dinesh kkkkkkkkkkkk");
    if (['dev'].includes(env)) {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [_reduxPersist.FLUSH, _reduxPersist.REHYDRATE, _reduxPersist.PAUSE, _reduxPersist.PERSIST, _reduxPersist.PURGE, _reduxPersist.REGISTER]
        }
      }).concat(_reduxLogger.default);
    }
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [_reduxPersist.FLUSH, _reduxPersist.REHYDRATE, _reduxPersist.PAUSE, _reduxPersist.PERSIST, _reduxPersist.PURGE, _reduxPersist.REGISTER]
      }
    });
  }
});
let persistor = exports.persistor = (0, _reduxPersist.persistStore)(store);
console.log(persistor);