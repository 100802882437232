"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usersLoading = exports.usersInfo = exports.initialState = exports.default = exports.currentUserForEdit = void 0;
var _toolkit = require("@reduxjs/toolkit");
const initialState = exports.initialState = {
  loading: false,
  users: [],
  userDataForEdit: {}
};
const userslices = (0, _toolkit.createSlice)({
  name: "users",
  initialState,
  reducers: {
    usersLoading: (state, action) => {
      if (action.payload !== undefined) {
        state.loading = action.payload;
      } else {
        state.loading = true;
      }
    },
    usersInfo: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    currentUserForEdit: (state, action) => {
      if (action.payload !== undefined) {
        state.userDataForEdit = action.payload;
      }
    }
  }
});
const {
  actions,
  reducer
} = userslices;
const {
  usersLoading,
  usersInfo,
  currentUserForEdit
} = actions;
exports.currentUserForEdit = currentUserForEdit;
exports.usersInfo = usersInfo;
exports.usersLoading = usersLoading;
var _default = exports.default = reducer;