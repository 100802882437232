"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.webSocketURL = exports.awsConfig_us_east_1 = exports.REACT_APP_ENV = exports.GOOGLE_KEY = void 0;
var awsConfig_us_east_1 = exports.awsConfig_us_east_1 = {
  Auth: {
    mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_9wdgrZWuP',
    identityPoolId: 'us-west-2:e4aa0cd9-b522-4628-b02e-c3654cf6ed4c',
    userPoolWebClientId: '22bsn27hk6qe6njbg8dtesqbns'
  },
  Storage: {
    region: 'us-west-2',
    bucket: 'dispatcheradmin-742142651343-us-west-2',
    identityPoolId: 'us-west-2:e4aa0cd9-b522-4628-b02e-c3654cf6ed4c'
  },
  API: {
    endpoints: [{
      name: 'GetCountry',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'GetRoles',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'GetUsers',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'updateProfile',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'CreateStore',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'updateStore',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'GetStore',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'GetCityState',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'searchStore',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'InviteEmployee',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'InviteEmployeeList',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'ResendInvite',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/user/user-service',
      region: 'us-west-2'
    }, {
      name: 'GetNotification',
      endpoint: 'https://ccwprodapiuswest2.pentair.cloud/notification',
      region: 'us-west-2'
    }, {
      name: "GetStores",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: 'us-west-2'
    }, {
      name: "TagStores",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: 'us-west-2'
    }, {
      name: "GetStoreSystem",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "GetStoreDetails",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: 'us-west-2'
    }, {
      name: "AddSystem",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "ListSystems",
      endpoint: "https://pimprodapiuswest2.pentair.cloud/list",
      region: 'us-west-2'
    }, {
      name: "GetStoreSystem",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "UpdateSystem",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "ProductFound",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "AddHub",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: 'us-west-2'
    }, {
      name: "AddSensor",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service/authenticateDevice",
      region: 'us-west-2'
    }, {
      name: "UpdateAccessory",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "DeleteAccessory",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "GetSystemDetails",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "GetAlerts",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service",
      region: 'us-west-2'
    }, {
      name: "UnTagStores",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: 'us-west-2'
    }, {
      name: "DeleteStores",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: 'us-west-2'
    }, {
      name: "DeleteUsers",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: 'us-west-2'
    }, {
      name: "UpdateSignIn",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: "us-west-2"
    }, {
      name: "NotificationAlert",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/notification",
      region: "us-west-2"
    }, {
      name: "GetDevice",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service/user/device",
      region: "us-west-2"
    }, {
      name: "ContactPentair",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: "us-west-2"
    }, {
      name: "GetAddSystemDetails",
      endpoint: "https://pimprodapiuswest2.pentair.cloud",
      region: "us-west-2"
    }, {
      name: "AddAccessory",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/device-service/system/",
      region: "us-west-2"
    }, {
      name: "SendPasswordChangeNotification",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: "us-west-2"
    }, {
      name: "commonUpdateUser",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: "us-west-2"
    }, {
      name: "syncverification",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/user-service",
      region: "us-west-2"
    }, {
      name: "storeImagesAPI",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/user/storeImages/",
      region: "us-west-2"
    }, {
      name: "s3Image",
      endpoint: "https://ccwprodapiuswest2.pentair.cloud/device/image",
      region: "us-west-2"
    }]
  }
};
const REACT_APP_ENV = exports.REACT_APP_ENV = 'prod';
const GOOGLE_KEY = exports.GOOGLE_KEY = 'AIzaSyBtEqnUG53rswWdKx3QHSa2SF5mQlM9mBs';
const webSocketURL = exports.webSocketURL = 'wss://sq9jswjg2d.execute-api.us-west-2.amazonaws.com/prod';